import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Chart } from 'primereact/chart';
import {Checkpermission} from "../../../hook/CheckPermission";

import { DashboardData,MaleFemaleData,PaidFreeUser,UserStatusCount } from "../../../Reducer/dashboardSlice";
import Layout from "../../Layouts/Layout";

export default function Dashboard() {
  const [chartData, setChartData] = useState({});
const [chartOptions, setChartOptions] = useState({});
const [paidfreeData, setpaidfreeData] = useState({});
const [paidfreeOptions, setpaidfreeOptions] = useState({});
const [malefemaleData, setmalefemaleData] = useState({});
const [malefemaleOptions, setmalefemaleOptions] = useState({});
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.data);

  useEffect(() => {
    dispatch(DashboardData());
  }, [dispatch]);

  const transformApiResponseToChartData = (response) => {
   

    return chartData;
};
  useEffect(() => {

    dispatch(UserStatusCount()).unwrap()
    .then((responseData) => {
   
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const monthLabels = [
        'January', 'February', 'March', 'April', 'May', 
        'June', 'July', 'August', 'September', 'October', 
        'November', 'December'
    ];

    const registeredData = [];
    const completedProfileData = [];
    const approvedUserData = [];
    const verifiedData = [];

    // Loop through the data and populate datasets
    responseData.data.data.forEach(item => {
        registeredData[item.month - 1] = item.Registered_User;
        completedProfileData[item.month - 1] = item.Completed_Profile;
        approvedUserData[item.month - 1] = item.Approved_User;
        verifiedData[item.month - 1] = item.Verified;
    });

    // Fill in any missing months with zeros
    for (let i = 0; i < monthLabels.length; i++) {
        if (registeredData[i] === undefined) registeredData[i] = 0;
        if (completedProfileData[i] === undefined) completedProfileData[i] = 0;
        if (approvedUserData[i] === undefined) approvedUserData[i] = 0;
        if (verifiedData[i] === undefined) verifiedData[i] = 0;
    }

    // Create chart data object
    const chartData = {
        labels: monthLabels,
        datasets: [
            {
                label: 'Registered Users',
                data: registeredData,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                tension: 0.4
            },
            {
                label: 'Completed Profiles',
                data: completedProfileData,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--pink-500'),
                tension: 0.4
            },
            {
                label: 'Approved Users',
                data: approvedUserData,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--green-500'),
                tension: 0.4
            },
            {
                label: 'Verified Users',
                data: verifiedData,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--orange-500'),
                tension: 0.4
            }
        ]
    };
    // const data = {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [
    //         {
    //             label: 'First Dataset',
    //             data: [65, 59, 80, 81, 56, 55, 40],
    //             fill: false,
    //             borderColor: documentStyle.getPropertyValue('--blue-500'),
    //             tension: 0.4
    //         },
    //         {
    //             label: 'Second Dataset',
    //             data: [28, 48, 40, 19, 86, 27, 90],
    //             fill: false,
    //             borderColor: documentStyle.getPropertyValue('--pink-500'),
    //             tension: 0.4
    //         }
    //     ]
    // };
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };

    setChartData(chartData);
    setChartOptions(options);
    })
    .catch((error) => {
    console.error('Error fetching data:', error);
    });
}, []);


useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    
    dispatch(PaidFreeUser()).unwrap()
      .then((responseData) => {
        console.log('Data successfully fetched and dispatched!');
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        // Prepare objects to hold data counts for each plan
        const freePlanData = {};
        const paidPlanData = {};
        
        // Initialize counts for each month as 0
        monthNames.forEach(month => {
          freePlanData[month] = 0;
          paidPlanData[month] = 0;
        });
        
        // Populate the data from the response
        responseData.data.data.forEach(item => {
          const monthName = monthNames[item.month - 1]; // Convert month number to month name
          freePlanData[monthName] = item.Free || 0;
          paidPlanData[monthName] = item.Paid || 0;
        });
        
        // Prepare final arrays for chart labels and data
        const labels = Object.keys(freePlanData); // All month names
        const freeData = Object.values(freePlanData); // Free plan counts for each month
        const paidData = Object.values(paidPlanData); // Paid plan counts for each month
        
        // Now construct the final chart data object
        const data = {
          labels, // Months
          datasets: [
            {
              label: 'Free User',
              data: freeData, // Counts for Free plan
              fill: false,
              borderColor: documentStyle.getPropertyValue('--blue-500'),
              tension: 0.4
            },
            {
              label: 'Paid User',
              data: paidData, // Counts for Paid plan
              fill: false,
              borderColor: documentStyle.getPropertyValue('--pink-500'),
              tension: 0.4
            }
          ]
        };
  
        const options = {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            }
          }
        };
  
        setpaidfreeData(data);
        setpaidfreeOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  
  }, []);
  
useEffect(() => {
dispatch(MaleFemaleData()).unwrap().then((responseData) => {
//   debugger
//   // This code will execute after the promise resolves
//     console.log('Data successfully fetched and dispatched!');
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const responseMonths = Object.keys(responseData.data.data);
    // Prepare the labels array and datasets for Male and Female
    const maleData = [];
    const femaleData = [];
    responseMonths.forEach(month => {
        // Add the count of Male and Female for each month
        maleData.push(responseData.data.data[month].Male);
        femaleData.push(responseData.data.data[month].Female);
    });
       

        // Generate the final data object for the chart
        const data = {
            labels: responseMonths,  // Use the months from the response
            datasets: [
                {
                    label: 'Male',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: maleData
                },
                {
                    label: 'Female',
                    backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    data: femaleData
                }
            ]
        };
       
//   const data = {
//       labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//       datasets: [
//           {
//               label: 'Male',
//               backgroundColor: documentStyle.getPropertyValue('--blue-500'),
//               borderColor: documentStyle.getPropertyValue('--blue-500'),
//               data: [65, 59, 80, 81, 56, 55, 40]
//           },
//           {
//               label: 'Female',
//               backgroundColor: documentStyle.getPropertyValue('--pink-500'),
//               borderColor: documentStyle.getPropertyValue('--pink-500'),
//               data: [28, 48, 40, 19, 86, 27, 90]
//           }
//       ]
//   };
  const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
          legend: {
              labels: {
                  fontColor: textColor
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: textColorSecondary,
                  font: {
                      weight: 500
                  }
              },
              grid: {
                  display: false,
                  drawBorder: false
              }
          },
          y: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          }
      }
  };

  setmalefemaleData(data);
  setmalefemaleOptions(options);
})
.catch((error) => {
//   debugger
  // This code will execute if there's an error in the promise
  console.error('Error fetching data:', error);
});

  
}, []);

  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12 lg:col-6 xl:col-3">
          <Link to="/Users">
            <div className="card mb-0">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                  Users
                  </span>
                  <div className="text-900 font-medium text-xl">{data && data.users}</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-user text-blue-500 text-xl" />
                </div>
              </div>
             
            </div>
            </Link>
          </div>
          <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                  Match Makers
                  </span>
                  <div className="text-900 font-medium text-xl">{data && data.makers}</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-orange-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-user text-orange-500 text-xl" />
                </div>
              </div>
             
            </div>
          </div>
          
        </div>
        <div className="card">
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
        <div className="card">
            <Chart type="line" data={paidfreeData} options={paidfreeOptions} />
        </div>
        <div className="card">
            <Chart type="bar" data={malefemaleData} options={malefemaleOptions} />
        </div>
      </Layout>
    </>
  );
}
