import Dashboard from './Components/Pages/Dashboard';
import HomePage from './Components/Pages/PageContent/HomePage';
import Resources from './Components/Pages/PageContent/Resouces/AllResources';
import AddResourcesPage from './Components/Pages/PageContent/Resouces/AddResourcesPage';
import EditResource from './Components/Pages/PageContent/Resouces/EditResource';
import Stories from './Components/Pages/PageContent/SuccessStories/AllStories';
import AddStories from './Components/Pages/PageContent/SuccessStories/AddStories';
import EditStories from './Components/Pages/PageContent/SuccessStories/EditStories';
import Sliders from './Components/Pages/PageContent/HomePage/SliderData/All';
import AddSliders from './Components/Pages/PageContent/HomePage/SliderData/Add';
import Transcends from './Components/Pages/PageContent/HomePage/Transcends/All';
import AddTranscends from './Components/Pages/PageContent/HomePage/Transcends/Add';
import EditTranscends from './Components/Pages/PageContent/HomePage/Transcends/Edit';
import Users from './Components/Pages/Users';
import SubAdmin from './Components/Pages/SubAdmin';
import ApproveUserList from './Components/Pages/Users/ApproveUserList';
import FieldChangeRequest from './Components/Pages/Users/FieldChangeRequest';
import Main from './Components/Pages/Users/Main';
import DownloadProfile from './Components/Pages/Users/DownloadProfile';
import KundliMatchingPage from './Components/Pages/PageContent/KundliMatchingPage';
import About from './Components/Pages/PageContent/About';
import Instructors from './Components/Pages/PageContent/Instructors/All';
import AddInstructors from './Components/Pages/PageContent/Instructors/Add';
import EditInstructors from './Components/Pages/PageContent/Instructors/Edit';
import Faq from './Components/Pages/PageContent/FAQ/All';
import AddFaq from './Components/Pages/PageContent/FAQ/Add';
import EditFaq from './Components/Pages/PageContent/FAQ/Edit';
import ContactQuery from './Components/Pages/ContactQuery';
import MatchMakers from './Components/Pages/MatchMakers/index';
import Details from './Components/Pages/MatchMakers/Details';
import ReportedUsers from './Components/Pages/ReportedUsers';
import Coupons from './Components/Pages/Coupons';
import AddCoupon from './Components/Pages/Coupons/AddCoupon';
import AllPlans from './Components/Pages/SubscriptionPlans/AllPlans';
import AddPlan from './Components/Pages/SubscriptionPlans/AddPlan';
import EditPlan from './Components/Pages/SubscriptionPlans/EditPlan';
import ChangePassword from './Components/Pages/Login/ChangePassword';
export const RouteList = [
    {
        path: "/Dashboard",
        component: <Dashboard />,
    },
    {
        path: "/SubAdmin",
        component: <SubAdmin />,
    },
    {
        path: "/Pages/HomePage",
        component: <HomePage />
    },
    {
        path: "/Pages/Sliders",
        component: <Sliders />
    },
    {
        path: "/Pages/AddSliders",
        component: <AddSliders />
    },
    {
        path: "/Pages/Transcends",
        component: <Transcends />
    },
    {
        path: "/Pages/AddTranscends",
        component: <AddTranscends />
    },
    {
        path: "/Pages/EditTranscends",
        component: <EditTranscends />
    },
    {
        path: "/Pages/Resources",
        component: <Resources />
    },
    {
        path: "/Pages/AddResources",
        component: <AddResourcesPage />
    },
    {
        path: "/Pages/EditResource",
        component: <EditResource />
    },
    {
        path: "/Pages/Stories",
        component: <Stories />
    },
    {
        path: "/Pages/AddStories",
        component: <AddStories />
    },
    {
        path: "/Pages/EditStories",
        component: <EditStories />
    },
    {
        path: "/Pages/KundliMatchingPage",
        component: <KundliMatchingPage />
    },
    {
        path: "/Pages/AboutUs",
        component: <About />
    },
    {
        path: "/Pages/Instructors",
        component: <Instructors />
    },
    {
        path: "/Pages/AddInstructors",
        component: <AddInstructors />
    },
    {
        path: "/Pages/EditInstructors",
        component: <EditInstructors />
    },
    {
        path: "/Pages/FAQs",
        component: <Faq />
    },
    {
        path: "/Pages/AddFaqs",
        component: <AddFaq />
    },
    {
        path: "/Pages/EditFaq",
        component: <EditFaq />
    },
    {
        path: "/Users",
        component: <Users />
    },
    {
        path: "/Users/:id",
        component: <Main />
    },
    {
        path: "/Users/download-profile/:id",
        component: <DownloadProfile />
    },
    {
        path: "/ContactQueries",
        component: <ContactQuery />
    },
    {
        path: "/MatchMakers",
        component: <MatchMakers />
    },
    {
        path: "/MatchMaker/:id",
        component: <Details />

    },
    {
        path: "/ReportedUsers",
        component: <ReportedUsers />
    },
    {
        path: "/Coupons",
        component: <Coupons />
    },
    {
        path: "/Coupons/AddCoupon",
        component: <AddCoupon />
    },
    {
        path: "/SubscriptionPlans",
        component: <AllPlans />
    },
    {
        path: "/SubscriptionPlans/Add",
        component: <AddPlan />
    },
    {
        path: "/SubscriptionPlans/Edit",
        component: <EditPlan />
    },
    {
        path:"/changepassword",
        component:<ChangePassword />
    },
    {
        path:"/Approve-User",
        component:<ApproveUserList />
    },
    {
        path:"/field-change-request",
        component:<FieldChangeRequest />
    }


];


