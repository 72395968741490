import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Slider } from "primereact/slider";
import { Checkbox } from 'primereact/checkbox';
import Swal from "sweetalert2";
import Sidebar from "../../Layouts/Sidebar";

import {
  ApproveUser,
  DeleteUser,
  clearUserRes,
  SubAdminList,
  AddSubAdmin
} from "../../../Reducer/userSlice";
import { API_BASE_URL } from "../../../Reducer/api";
import DataTable from "react-data-table-component";
import Layout from "../../Layouts/Layout";
import DynamicDataTable from "../../../Utils/DynamicDataTable";
import "./style.css"
export default function SubAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
    name: "",
    email:"",
    mobile:""
  });
  const pages = [
    { page: 'Users List', subPages: ['All User', 'Approve Pending', 'Field Change Request'] },
    { page: 'Dashboard' },
    { page: 'Match Makers' },
    { page: 'Resoursces' },
    { page: 'Pages', subPages: ['HomePage', 'Home Slider', 'Home Transcends', 'Success Stories', 'About Page', 'Instructors', 'Kundali Matching Page', 'FAQs'] },
    { page: 'Contact Queries' },
    { page: 'Reported Users' },
    { page: 'Coupons' },
    { page: 'Subscription Plans' },
    { page: 'Sub admin' },
];
const [permissions, setPermissions] = useState({});
  const users = useSelector((state) => state.user.SubAdminListdata);
  const success = useSelector((state) => state.user.success);
  const error = useSelector((state) => state.user.error);

  if (success && success.status == 200) {
    console.log("successs", success);
    if (success.data.data) {
      dispatch(clearUserRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearUserRes());
      dispatch(SubAdminList());
    }
  }

  if (error && error.status !== 200) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearUserRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearUserRes());
    }
  }

  useEffect(() => {
    dispatch(SubAdminList());
  }, []);
  const Imagepro = ({ record, API_BASE_URL }) => {
    return (
      <div>
        {record && record.profileImage && (
          <img
            src={API_BASE_URL + record.profileImage}
            alt="Profile"
            style={{ width: "100px", height: "auto" }}
          />
        )}
      </div>
    );
  };

  const columns = [
    {
      field: "profileImage",
      header: "Image",
      body: (rowData) => (
        <Imagepro record={rowData} API_BASE_URL={API_BASE_URL} />
      ),
    },
    {
      field: "name",
      header: "Name",
      right: true,
      filter: true,
      filterPlaceholder: "Search by name",
      style: { minWidth: "12rem" },
    },
    {
      field: "email",
      header: "Email",
      right: true,
      filter: true,
      filterPlaceholder: "Search by name",
      style: { minWidth: "12rem" },
    },
    {
      field: "mobile",
      header: "Mobile",

      right: true,
      filter: true,
      filterPlaceholder: "Search by name",
      style: { minWidth: "12rem" },
    },
    {
      field: "",
      header: "Action",
      filter: false,
      style: { minWidth: "12rem" },
      body: (record) => (
        <>
         
          <a onClick={() => delUser(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
        </>
      ),
    },
   
    
  ];

  function delUser(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let data = { id: id };
      dispatch(DeleteUser(data));
    }
  }
  function ApproveProfile(id) {
    let data = { id: id };
    dispatch(ApproveUser(data));
  }
  const inputsHandler = (e) => {
      
   
      
        setInputField({
          ...inputField,
          [e.target.name]: e.target.value
        });
      
      
    
    
    
      
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(permissions);
    const permi=filterPermissions(permissions);
    debugger
    try {
        console.log('inputField', inputField);
        let formData = new FormData();
        formData.append('name', inputField.name);
        formData.append('email', inputField.email);
        formData.append('mobile', inputField.mobile);
        formData.append('permission', JSON.stringify(permi));
        
     dispatch(AddSubAdmin(formData));
    } catch (e) {
      
        console.error('Error during login:', e);

    }finally{
        dispatch(SubAdminList());
    };
}
const filterPermissions = (permissions) => {
    const updatedPermissions = {};

    Object.entries(permissions).forEach(([key, value]) => {
        // Remove the "Pages-" or "Users List-" prefix if it exists
        if (key.startsWith('Pages-')) {
            updatedPermissions[key.replace('Pages-', '')] = value;
        } else if (key.startsWith('Users List-')) {
            updatedPermissions[key.replace('Users List-', '')] = value;
        } else {
            updatedPermissions[key] = value;
        }
    });

    return updatedPermissions;
};

const handleChange = (key, type, checked) => {
    setPermissions((prev) => ({
        ...prev,
        [key]: { ...prev[key], [type]: checked },
    }));
};
const PermissionRow = ({ page, subPage = null, permissions, handleChange }) => {
    const key = subPage ? `${page}-${subPage}` : page;
    const perms = permissions[key] || { create: false, read: false, update: false, delete: false };

    return (
        <div className="permission-row">
            <span className="permission-title">{subPage || page}</span>
            <div className="permission-options">
                {['create', 'read', 'update', 'delete'].map((type) => (
                    <div key={type} className="permission-option">
                        <label htmlFor={`${key}-${type}`} className="permission-label">
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </label>
                        <Checkbox
                            inputId={`${key}-${type}`}
                            checked={perms[type]}
                            onChange={(e) => handleChange(key, type, e.checked)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};



  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card row-12">
            <form onSubmit={handleSubmit}>
            <div className="row-6">   
            <div className="field col-12 md:col-6">
                <label htmlFor="firstname2">Name</label>

                <InputText
                  id="name"
                  type="text"
                 className="p-inputtext-lg"
                 onChange={inputsHandler}
                  name="name"
                  required
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="firstname2">email</label>

                <InputText
                  id="email"
                  type="text"
                  className="p-inputtext-lg"
                  onChange={inputsHandler}
                  name="email"
                  required
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="firstname2">mobile</label>

                <InputText
                  id="mobile"
                  type="text"
                 className="p-inputtext-lg"
                 onChange={inputsHandler}
                  name="mobile"
                  required
                />
              </div>
              </div> 
              <div className="row-6">  
              <h1>Permissions Management</h1>
            <div className="permission-list">
                {pages.map((page) => (
                    <div key={page.page} className="permission-section">
                        <h2>{page.page}</h2>
                        {page.subPages
                            ? page.subPages.map((subPage) => (
                                  <PermissionRow
                                      key={subPage}
                                      page={page.page}
                                      subPage={subPage}
                                      permissions={permissions}
                                      handleChange={handleChange}
                                  />
                              ))
                            : (
                                <PermissionRow
                                    page={page.page}
                                    permissions={permissions}
                                    handleChange={handleChange}
                                />
                            )}
                    </div>
                ))}
            </div>
            </div>
              <div className="space-10"></div>

              <Button label="Submit" type="submit" severity="Primary" />
              </form>
            </div>
            <div className="card">
              <h5>User List </h5>

              <DynamicDataTable
                data={users}
                columns={columns}
                loading={false}
                emptyMessage="No User found."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
