import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Slider } from "primereact/slider";

import Swal from "sweetalert2";
import Sidebar from "../../Layouts/Sidebar";


        
import { ApproveUser,DeleteUser, UserList, clearUserRes,uploadUserExelData,getUserpendinglistData } from "../../../Reducer/userSlice";
import { API_BASE_URL } from "../../../Reducer/api";
import DataTable from "react-data-table-component";
import Layout from "../../Layouts/Layout";
import DynamicDataTable from "../../../Utils/DynamicDataTable";
import MatchMakerModal from "./MatchMakerModal";
import {exportToExcel} from "../../reusable-component/excel/exportexcel"
import { Checkpermission } from "../../../hook/CheckPermission";
export default function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if(!Checkpermission("All User")){
    navigate('/Dashboard');
  }
  const [inputField, setInputField] = useState({
 
    image: null,
  });
  
  const [open, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const [file, setFile] = useState();
  const [selectedUserType, setUserType] = useState(null);
  const [selectedTerm, setTerm] = useState(null);
  const [selectedgender, setgender] = useState(null);
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState([18,80]);
  const UserType = [
      { name: 'Select User', code: null },
      { name: 'Excel User', code: 'EXL' },
      { name: 'Normal User', code: 'NORMAL' },
   
  ];
  const Terms = [
    { name: 'Select Term', code: null },
    { name: 'Accepted Term', code: 'AcceptedTerm' },
    { name: 'Not Accepted Term', code: 'NotAcceptedTerm' },
 
];
const gender = [
  { name: 'Select Gender', code: null },
  { name: 'Male', code: 'Male' },
  { name: 'Female', code: 'Female' },

];
  const users = useSelector((state) => state.user.data);
  const success = useSelector((state) => state.user.success);
  const error = useSelector((state) => state.user.error);

  if (success && success.status == 200) {
    console.log("successs", success);
    if (success.data.data) {
      dispatch(clearUserRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearUserRes());
      dispatch(UserList());
    }
  }

  if (error && error.status !== 200) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearUserRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearUserRes());
    }
  }
  
  useEffect(() => {
    dispatch(UserList());
  }, []);
  // useEffect(() => {
  //   if (!Checkpermission("All User")) {
  //     navigate('/Dashboard');
  //   }
  // }, [navigate]);
  const Imagepro=({record,API_BASE_URL})=>{
    return (
      <div>
          {record && record.profileImage && (
                  <img
                      src={API_BASE_URL + record.profileImage}
                      alt="Profile"
                      style={{ width: "100px", height: "auto" }}
                  />
              )}
      </div>
      
    )
  }
  const OpenModal = (id) => {
    setId(id);
    setOpenModal(true);
}
  const columns = [
    {
        
        field: "profileImage",
        header: 'Image',
        body: (rowData) => <Imagepro record={rowData} API_BASE_URL={API_BASE_URL} />
    
    },
    {
       
        field: "name",
        header: "Name",    
        right: true,
        filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "email",
        header: "Email",    
        right: true,
        filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "mobile",
        header: "Mobile",
      
      right: true,
      filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
      field: "Mother_tongue",
      header: "Mother tongue",
    
    right: true,
    filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
  },
  {
       
    field: "caste",
    header: "caste",
  
  right: true,
  filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
},
{
       
  field: "temple",
  header: "Temple",

right: true,
filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
},{
       
  field: "temple_associated_type",
  header: "temple associated type",

right: true,
filter: true, filterPlaceholder: 'Search by temple associated', style: { minWidth: '12rem' }
},
{
       
  field: "prefered_affiliation",
  header: "prefered affiliation",

right: true,
filter: true, filterPlaceholder: 'Search by affiliation', style: { minWidth: '12rem' },
body: (record) => (
  <>
  {record.prefered_affiliation && record.prefered_affiliation.length > 0
        ? record.prefered_affiliation.join(", ") // Join array into a comma-separated string
        : ""}
  </>
)
},
    {
       
      field: "age",
      header: "Age",
    
    right: true,
    filter: true, filterPlaceholder: 'Search by Age', style: { minWidth: '5rem' }
  },
    {
        
        field: "",
        header: "Action",
        filter: false, style: { minWidth: '12rem' },
        body: (record) => (
        <>
          {Checkpermission("All User","read") &&
          <Link to={`/Users/${record._id}`}>
            <i className="fa fa-eye"></i>
            <Button label="Info" severity="info" icon="pi pi-eye" />
          </Link>}
          {Checkpermission("All User","create") &&
          <Link to={`/Users/download-profile/${record._id}`}>
            <i className="bi bi-download"></i>
            <Button label="Download" severity="info" icon="pi pi-eye" />
          </Link>}
          {Checkpermission("All User","delete") &&
          <a onClick={() => delUser(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>}
        </>
      ),
    },{
        
      field: "",
      header: "Profile Status",
      filter: false, style: { minWidth: '12rem' },
      body: (record) => (
      <>
       {Checkpermission("All User", "update") && (
  record.profile_status && record.profile_status === "Approved" ? (
    <Button label="Approved" severity="success" />
  ) : (
    <Button label="Approve" severity="Primary" onClick={() => ApproveProfile(record._id)} />
  )
)}    
        
      </>
    ),
  },{
        
    field: "",
    header: "Assigned To",
    filter: false, style: { minWidth: '12rem' },
    body: (record) => (
    <>
     
     {Checkpermission("All User", "update") && (
  <>
    {record.Assigned_to && <Button label="Assigned" severity="success" />}
    <Button label="Assign to MatchMaker" severity="info" onClick={() => OpenModal(record._id)} />
  </>
)}
                    
      
    </>
  ),
}
  ];

  function delUser(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let data = { id: id };
      dispatch(DeleteUser(data));
    }
  }
  function ApproveProfile(id) {
    let data = { id: id };
    dispatch(ApproveUser(data));
}
const handleSubmit = (e) => {
  e.preventDefault();
  try {
    let formData = new FormData();
   
    formData.append("image", inputField.image);
    dispatch(uploadUserExelData(formData));
  } catch (e) {
    console.error("Error during login:", e);
  }
};
const  handleChange=(e) =>{
  if (e.target.name == "image") {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.files[0],
    });
    setFile(URL.createObjectURL(e.target.files[0]));
  } 
}
const getUserpendinglist=async()=>{
  
  const result=await dispatch(getUserpendinglistData());
  if(result.payload.data.data.length >0){
    exportToExcel(result.payload.data.data,"allUserInfo")
  }else
  {
    alert("no data found")
  }
 
  
}
const handleSearch=()=>{
 
 
  let formData = new FormData();
   if(selectedUserType?.code !==undefined && selectedUserType?.code !==null){
    formData.append("selectedUserType", selectedUserType?.code);
   }
   if(selectedTerm?.code !==undefined && selectedTerm?.code !==null){
    formData.append("selectedTerm", selectedTerm?.code);
   }
   if(selectedgender?.code !==undefined && selectedgender?.code !==null){
    formData.append("gender", selectedgender?.code);
   }

  
   if(value !==undefined && value !==null){
    formData.append("startage", value[0]);
    formData.append("endage", value[1]);
   }
   if(dates !==undefined && dates !==null){
    formData.append("startdate", dates[0]);
    formData.append("enddate", dates[1]);
   }
 
  dispatch(UserList(formData));
}
  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
          {/* <div className="card flex justify-content-center">
            <Dropdown value={selectedUserType} onChange={(e) => setUserType(e.value)} options={UserType} optionLabel="name" 
                placeholder="Select User Type" className="w-full md:w-14rem" />
                <div className="space-10"></div>
                            <Dropdown value={selectedTerm} onChange={(e) => setTerm(e.value)} options={Terms} optionLabel="name" 
                placeholder="Select term type" className="w-full md:w-14rem" />
                <div className="space-10"></div>
                <Dropdown value={selectedgender} onChange={(e) => setgender(e.value)} options={gender} optionLabel="name" 
                placeholder="Select gender" className="w-full md:w-14rem" />
                 <div className="space-10"></div>
                 <div className="flex">
                 <label htmlFor="calendar-12h" className="font-bold block mb-2">
                   Date Range
                </label>
                <div className="space-10"></div>
                <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection />
       
                 </div>
                 <div className="flex">
                 <label htmlFor="calendar-12h" className="font-bold block mb-2">
                   Age Range({`${value[0]} - ${value[1]}`})
                </label>
                <div className="space-10"></div>
                <Slider value={value} onChange={(e) => setValue(e.value)} className="w-14rem" range />
                 </div>
                <div className="space-10"></div>
                <Button label="Search" onClick={handleSearch}/>
        </div> */}
       <div className="card p-4 w-full">
  <div className="grid">
    {/* User Type */}
    <div className="col-4">
      <Dropdown value={selectedUserType} onChange={(e) => setUserType(e.value)} options={UserType} optionLabel="name" 
        placeholder="Select User Type" className="w-full" />
    </div>

    {/* Term Type */}
    <div className="col-4">
      <Dropdown value={selectedTerm} onChange={(e) => setTerm(e.value)} options={Terms} optionLabel="name" 
        placeholder="Select Term Type" className="w-full" />
    </div>

    {/* Gender */}
    <div className="col-4">
      <Dropdown value={selectedgender} onChange={(e) => setgender(e.value)} options={gender} optionLabel="name" 
        placeholder="Select Gender" className="w-full" />
    </div>

    {/* Date Range */}
    <div className="col-6">
    <label htmlFor="calendar-12h" className="font-bold mb-3 block">Date Range (Select a start and end date in the same calendar)</label>
      <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection 
        className="w-full" />
    </div>

    {/* Age Range */}
    <div className="col-6">
      <label htmlFor="age-range" className="font-bold mb-3 block">Age Range ({`${value[0]} - ${value[1]}`})</label>
      <Slider value={value} onChange={(e) => setValue(e.value)} className="w-full" range />
    </div>

    {/* Search Button (Centered) */}
    <div className="col-12 flex justify-center mt-4">
      <Button label="Search" onClick={handleSearch} />
    </div>
  </div>
</div>




            <div className="card">
              <h5>User List(Count : {users.length})   <Button label="Export user" onClick={getUserpendinglist}/></h5>
              
              <DynamicDataTable
                data={users}
                columns={columns}
                loading={false}
                emptyMessage="No User found."
            />
              
            </div>
          </div>
        </div>
        {open === true &&
                            <MatchMakerModal setOpenModal={setOpenModal} id={id} status={open} />
                        }
      </Layout>
     
    </>
  );
}
