import React, { useEffect, useRef, useState } from "react";
import "./MatrimonialProfile.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
    API_BASE_URL,

} from "../../../../Reducer/api";
import { useDispatch, useSelector } from "react-redux";
const DownloadProfile = () => {
    const { id } = useParams();
    const [userInfo, setUserInfo] = useState({});
    const profileContainerRef = useRef(null);
    const printRef = useRef();
    const auth = useSelector((state) => state.auth.auth);
    const [theme, setTheme] = useState('');
    const handleChangeTheme = (theme) => {
        // debugger
        const pdfDownloadDiv = document.querySelector('.kk');
        if (pdfDownloadDiv) {
            pdfDownloadDiv.className = `kk ${theme}`;
        }
        // document.body.className = theme;
    };

    const setLayout = (layout) => {
        if (profileContainerRef.current) {
            profileContainerRef.current.className = `profile-container ${layout}`;
            localStorage.setItem("selectedLayout", layout); // Save the layout to localStorage
        }
    };
    useEffect(() => {
        const savedTheme = "theme-default";
        const savedLayout = "layout-two-column";

        handleChangeTheme(savedTheme);
        setLayout(savedLayout);
    }, []);
    
    useEffect(() => {
        if (!id) {
            console.error("No user ID provided in the route.");
            return;
        }
        // handlePrint();
        let formData = new FormData();
        formData.append("id", id);
        
        axios
            .post(`${API_BASE_URL}common/downloadProfile`, formData)
            .then((response) => {
                if (response.status === 200) {
                    setUserInfo(response?.data?.data?.[0]);
                    debugger
                }
            })
            .catch((error) => {
                // Handle error
                console.error("Error while downloading profile:", error);
            });
        //handleDownload()
    }, []);
    useEffect(() => {
        console.log(userInfo);
    }, [userInfo]);
    const handleDownload = async () => {
        window.print()
        // if (printRef.current) {
        //     const element = printRef.current;
        //     html2pdf()
        //         .set({
        //             margin: 1,
        //             filename: "document.pdf",
        //             html2canvas: { scale: 2 },
        //             jsPDF: { orientation: "portrait" },
        //         })
        //         .from(element)
        //         .save();
        // } else {
        //     console.error("No element found to print");
        // }
    };
    const getuserGallery=()=>{
        
        if(Object.keys(userInfo).length >0){
            const userGallery = userInfo.modelData.find(
                (model) => model.modelName === "UserGalleryUpload"
              );
              
              if (userGallery && userGallery.documents.length > 0) {
               return  `${API_BASE_URL}${userGallery.documents[0].photos[0]}`;
                
              } else {
                return `${API_BASE_URL}${"team.png"}`;
              }
        }
        
    }
    return (
        <div className={`pdf-download-css`} >
            <head>
                <meta charset="UTF-8" />
                <title>Matrimonial Profile - Rakesh Kumar Malik</title>

                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Playfair+Display:wght@700&family=Lato:wght@700&family=Montserrat:wght@700&family=Open+Sans:wght@700&display=swap"
                    rel="stylesheet"
                />

                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
                />
            </head>
            <body>
                <div className={`kk ${theme}`}>
                <img
                    src={API_BASE_URL+"watermark.png" }
                    
                    className="watermark-screen"
                    alt="Watermark"
                />

                <img
                    src={API_BASE_URL+"watermark.png" }
                    className="watermark-print"
                    alt="Watermark"
                />

                <div className="selectors">
                    <div className="selector-group">
                        <h3>Select Template</h3>
                        <button className="theme-button" onClick={()=>handleChangeTheme('theme-default')} style={{ backgroundImage: `url(${API_BASE_URL}theme-default.jpg)` }} title="Default"> <span className="theme-name">Default</span></button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-modern')} style={{backgroundImage: `url(${API_BASE_URL}theme-modern.jpg)`}} title="Modern">Modern</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-dark')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-dark.jpg)` }} title="Dark">Dark</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-colorful')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-colorful.jpg)` }} title="Colorful">Colorful</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-minimalist')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-minimalist.jpg)` }} title="Minimalist"><span className="theme-name">Minimalist</span></button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-elegant')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-elegant.jpg)` }} title="Elegant"><span className="theme-name">Elegant</span></button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-vibrant')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-vibrant.jpg)` }} title="Vibrant">Vibrant</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-boxed')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-boxed.jpg)` }} title="Boxed"><span className="theme-name">Boxed</span></button>
           
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-rustic')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-rustic.jpg)` }} title="Rustic">Rustic</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-futuristic')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-futuristic.jpg)` }} title="Futuristic">Futuristic</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-nature')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-nature.jpg)` }} title="Nature">Nature</button>
            <button className="theme-button" onClick={()=>handleChangeTheme('theme-artistic')} style={{ backgroundImage: `url(${API_BASE_URL}/theme-artistic.jpg)` }} title="Artistic">Artistic</button>
                    </div>

                    {/* <!-- Layout Selector --> */}
                    <div className="selector-group">
                        <h3>Select Layout</h3>
                        <button
                            className="layout-button"
                            onClick={()=>setLayout('layout-two-column')}
                        >
                            <i className="fas fa-columns"></i> Two-Column
                        </button>
                        <button
                            className="layout-button"
                            onClick={()=>setLayout('layout-single-column')}
                        >
                            <i className="fas fa-list"></i> Single-Column
                        </button>
                        <button
                            className="layout-button"
                            onClick={()=>setLayout('layout-grid')}
                        >
                            <i className="fas fa-th"></i> Grid
                        </button>
                    </div>
                </div>

                {/* <!-- Print Button for Screen --> */}
                <button class="print-button" onClick={handleDownload}>
                    <i class="bi bi-download"></i> Download
                </button>
                <div ref={profileContainerRef} className="profile-container layout-two-column" >
                    {/* <!-- Left Column --> */}
                    <div className="profile-left">
                        {/* <!-- Profile Header Section with Fixed Size --> */}
                        <div className="section fixed-size-section-x">
                            <div className="profile-header">
                            <img 
                                src={userInfo?.user?.profileImage === "team.png" ? getuserGallery() : `${API_BASE_URL}${userInfo?.user?.profileImage}`} 
                                alt="Profile Picture" 
                                className="profile-pic"
                                />
                                {/* <img src={`${API_BASE_URL}${userInfo?.user?.profileImage}`} alt="Profile Picture" className="profile-pic"/> */}
                                <div>
                                    <h2>
                                        {userInfo?.user?.name}
                                      
                                        <img src={`${API_BASE_URL}${"verified.png"}`} alt="Verified" className="verified-icon" />
                                    </h2>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-phone"></i>{" "}
                                            {userInfo?.user?.mobile}
                                        </div>
                                        <div>
                                            <i className="fas fa-envelope"></i>{" "}
                                            {userInfo?.user?.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Basic Info --> */}
                        {userInfo?.modelData?.[0]?.documents?.[0] &&

                        <div className="section">
                            <div className="section-title">Basic Info</div>
                            <div className="section-content">
                                <div className="info-item">
                                    <span className="label">Name:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[0]?.documents?.[0]?.name}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Gender:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.gender}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Marital Status:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.Marital_status}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Mother Tongue:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.Mother_tongue}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Height:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.height}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Weight:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.weight}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Health Status:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[0]?.documents?.[0]?.health}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Any Disability:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.Disability}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Country:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.country}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">State:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.state}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">City:</span>
                                    <span className="value">{userInfo?.modelData?.[0]?.documents?.[0]?.city}</span>
                                </div>
                            </div>
                        </div>
                        }

                        {/* <!-- Career & Education --> */}
                        {userInfo?.modelData?.[3]?.documents?.[0] &&
                        <div className="section">
                            <div className="section-title">Career & Education</div>
                            <div className="section-content">
                                <div className="info-item">
                                    <span className="label">Employer’s Name:</span>
                                    <span className="value">{userInfo?.modelData?.[3]?.documents?.[0]?.company_name}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Profession:</span>
                                    <span className="value">{userInfo?.modelData?.[3]?.documents?.[0]?.occupation}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Annual Income:</span>
                                    <span className="value">{userInfo?.modelData?.[3]?.documents?.[0]?.income}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Job Location:</span>
                                    <span className="value">{userInfo?.modelData?.[3]?.documents?.[0]?.job_location}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">
                                        Highest Qualification:
                                    </span>
                                    <span className="value">
                                    {userInfo?.modelData?.[3]?.documents?.[0]?.Ed_qalification}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">College:</span>
                                    <span className="value">{userInfo?.modelData?.[3]?.documents?.[0]?.Ed_institution}</span>
                                </div>
                            </div>
                        </div>
                        }
                        {/* <!-- Astro --> */}
                        {userInfo?.modelData?.[1]?.documents?.[0] &&
                        <div className="section">
                            <div className="section-title">Astro</div>
                            <div className="section-content">
                                <div className="info-item">
                                    <span className="label">Date of Birth:</span>
                                    <span className="value">{userInfo?.modelData?.[1]?.documents?.[0]?.dob}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Time of Birth:</span>
                                    <span className="value">{`${userInfo?.modelData?.[1]?.documents?.[0]?.time} ${userInfo?.modelData?.[1]?.documents?.[0]?.ampm}`}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">
                                        Mangalik / Chevval Dosham:
                                    </span>
                                    <span className="value">{userInfo?.modelData?.[1]?.documents?.[0]?.manglik}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Nakshatra:</span>
                                    <span className="value">{userInfo?.modelData?.[1]?.documents?.[0]?.nakshtra}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Place of Birth:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[1]?.documents?.[0]?.birth_place}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Religion:</span>
                                    <span className="value">{userInfo?.modelData?.[1]?.documents?.[0]?.religion}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Gothram:</span>
                                    <span className="value">{userInfo?.modelData?.[1]?.documents?.[0]?.gotra}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Cast:</span>
                                    <span className="value">{userInfo?.modelData?.[1]?.documents?.[0]?.caste}</span>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    {/* <!-- Right Column --> */}
                   
                    <div className="profile-right">
                        {/* <!-- About Me Section with Fixed Size --> */}
                        {userInfo?.modelData?.[7]?.documents?.[0] &&
                        <div className="section fixed-size-section-x">
                            <h2>About Me</h2>
                            <p>
                            {userInfo?.modelData?.[7]?.documents?.[0]?.about?.length > 486 
    ? userInfo.modelData[7].documents[0].about.slice(0, 486) + "..." 
    : userInfo?.modelData?.[7]?.documents?.[0]?.about}
                            </p>
                        </div>
                        }         
                        {/* <!-- Family --> */}
                        {userInfo?.modelData?.[2]?.documents?.[0] &&
                        <div className="section">
                            <div className="section-title">Family</div>
                            <div className="section-content">
                                <div className="info-item">
                                    <span className="label">Father’s Name:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[2]?.documents?.[0]?.father_name}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">
                                        Father’s Employment:
                                    </span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.father_occcupation}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Mother’s Name:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.mother_name}</span>
                                </div>

                                <div className="info-item">
                                    <span className="label">
                                        Mother’s Employment:
                                    </span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.mother_occcupation}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Family Values:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.family_status}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Family Affluence:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.familyCulture}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Family Location:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[2]?.documents?.[0]?.location}
                                    </span>
                                </div>
                                {userInfo?.modelData?.[2]?.documents?.[0]?.younger_brother >0 &&
                                <div className="info-item">
                                    <span className="label">Younger Brother:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.younger_brother}</span>
                                </div>
                                }
                                {userInfo?.modelData?.[2]?.documents?.[0]?.elder_brother >0 &&
                                <div className="info-item">
                                    <span className="label">Elder Brother:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.elder_brother}</span>
                                </div>}
                                {userInfo?.modelData?.[2]?.documents?.[0]?.younger_sister >0 &&
                                <div className="info-item">
                                    <span className="label">Younger Sister:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.younger_sister}</span>
                                </div>}
                                {userInfo?.modelData?.[2]?.documents?.[0]?.elder_sister >0 && 
                                <div className="info-item">
                                    <span className="label">Elder Sister:</span>
                                    <span className="value">{userInfo?.modelData?.[2]?.documents?.[0]?.elder_sister}</span>
                                </div>}
                            </div>
                        </div>
                        }
                        
                        {/* <!-- Personality --> */}
                        {userInfo?.modelData?.[6]?.documents?.[0] &&
                        <div className="section">
                            <div className="section-title">Personality</div>
                            <div className="section-content">
                                <div className="info-item">
                                    <span className="label">
                                        Are you more comfortable in social
                                        gatherings?*
                                    </span>
                                    <span className="value">{userInfo?.modelData?.[6]?.documents?.[0]?.social_Gatherings}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Your Diet:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[6]?.documents?.[0]?.diet}
                                    </span>
                                </div>
                            </div>
                        </div>
                        }
                        {/* <!-- Preferences --> */}
                        {userInfo?.modelData?.[5]?.documents?.[0] &&
                        <div className="section">
                            <div className="section-title">Preferences</div>
                            <div className="preferences">
                                {/* <!-- Basic Preference --> */}
                                <div className="preference-category">
                                    <h3>Basic Preference</h3>
                                    <div className="section-content">
                                        <div className="info-item">
                                            <span className="label">
                                                Marital Status:
                                            </span>
                                            <span className="value"> {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_marital_status
    ?.map(status => status.label)
    .join(", ") || ""}</span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Preferred Age:
                                            </span>
                                            <span className="value">
                                            {`${userInfo?.modelData?.[5]?.documents?.[0]?.prefered_age_start} ${userInfo?.modelData?.[5]?.documents?.[0]?.prefered_age_end} years`}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Preferred Height:
                                            </span>
                                            <span className="value">
                                            {`${userInfo?.modelData?.[5]?.documents?.[0]?.prefered_height_start} ${userInfo?.modelData?.[5]?.documents?.[0]?.prefered_height_end}`}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Preferred Food:
                                            </span>
                                            <span className="value">
                                            {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_food?.map(status => status.label)
    .join(", ") || ""}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Preferred Language:
                                            </span>
                                            <span className="value">
                                            {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_language?.map(status => status.label)
    .join(", ") || ""}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Preferred Affiliation:
                                            </span>
                                            <span className="value"> {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_affiliation?.map(status => status.label)
    .join(", ") || ""}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Community Preferences --> */}
                                <div className="preference-category">
                                    <h3>Community Preferences</h3>
                                    <div className="section-content">
                                        <div className="info-item">
                                            <span className="label">Religion:</span>
                                            <span className="value">
                                            {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_religion}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">Caste:</span>
                                            <span className="value">
                                            {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_caste}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Community:
                                            </span>
                                            <span className="value">
                                            {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_community}
                                            </span>
                                        </div>
                                        <div className="info-item">
                                            <span className="label">
                                                Cultural Values:
                                            </span>
                                            <span className="value">
                                            {userInfo?.modelData?.[5]?.documents?.[0]?.prefered_cultr_val}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }  
                        {userInfo?.modelData?.[4]?.documents?.[0] &&
                        <div className="section">
                            <div className="section-title">Spiritual</div>
                            <div className="section-content">
                                <div className="info-item">
                                    <span className="label">Spiritual Organisation Affiliation:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[4]?.documents?.[0]?.affiliated_with_spiritual_organization}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">
                                    How Long?:
                                    </span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.affiliated_duration}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How much time you spend daily on your spiritual sadhana?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.sadhanaTime}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Since when have you been practicing Krishna Consciousness?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.practicing_time_period}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Chanting rounds?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.chanting_rounds}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How often do you visit temple?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.temple_visit}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Spirituality's role in your life?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.spirituality_role}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Who is your spiritual master?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.spiritual_master}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Are you initiated?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.initiated}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Initiation Name:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.Initiation_name}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Do you follow Four Regulative Principles:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.four_Regulative_Principles}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Do your parents practice Krishna Consciousness?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.parents_practice_Krishna}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">List of Prabhupad's book read by you:</span>
                                    <span className="value">
                                    {userInfo?.modelData?.[4]?.documents?.[0]?.selectedBooks
    ?.map(status => status.value)
    .join(", ") || ""}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">What Srila Prabhupada book are you currently reading?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.currently_reading}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Do you observe fasting on Ekadashi days?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.ekadashi_fasting}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Which ISKCON/HKM Centre are you associated with?*:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.iskcon_associted_with}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How are you associated with the temple?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.temple_associated_type}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.attended_seminar}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Are you open to engaging in devotional services or seva together as a couple?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.engaged_devotional_services}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How important is living in close proximity to a temple or devotional community for you?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.living_in_temple}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Are you open to further deepening your understanding and practice of Krishna Consciousness after marriage?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.Krishna_Consciousness_after_marriage}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">What is your understanding and acceptance of the principles and teachings of Srila Prabhupada?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.tech_of_Srila_Prabhupada}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How do you envision supporting and encouraging each other's spiritual growth within the marriage?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.spritulGrowthMarriage}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How do you envision incorporating Krishna Consciousness into your married life?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.incorporating_Krishna_Consciousness}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">How do you handle challenges or conflicts in your spiritual journey?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.conflicts_spiritualJourny}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Do you have any specific spiritual goals or
                                    aspirations that you would like to share?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.specific_spiritual_goals}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Do you have a spiritual mentor or guide? If yes, please provide
                                    details?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.spiritual_mentor}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">What temple service are you engaged in?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.temple_services}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Department Name?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.department_name}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">Designation?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.designation_name}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">What are the various roles and experiences you have had in your
                                    service at the temple?:</span>
                                    <span className="value">{userInfo?.modelData?.[4]?.documents?.[0]?.roles_and_experiences}</span>
                                </div>

                                
                                
                               
                                
                                
                              
                                
                            </div>
                        </div>
                        }                     
                    </div>
                    
                    {/* <!-- Footer --> */}
                    <div className="footer">
                        &copy; 2024 https://vedicmarriage.ai. All rights
                        reserved.
                    </div>
                </div>
                </div>
            </body>
        </div>
    );
};

export default DownloadProfile;

