import React, { useContext } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "../../context/layoutcontext";
import { MenuProvider } from "../../context/menucontext";
import { Link } from "react-router-dom";

const AppMenu = () => {
  const { layoutConfig } = useContext(LayoutContext);

  const model = [
    {
      items: [
        { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/Dashboard" },
      ],
    },
    {
      items: [
        { label: "Sub admin", icon: "pi pi-fw pi-home", to: "/SubAdmin" },
      ],
    },

    {
      items: [
        {
          label: "Users List",
          icon: "",
          items: [
            { label: "All User", to: "/Users" },
            { label: "Approve Pending", to: "/Approve-User" },
            { label: "Field Change Request", to: "/field-change-request" },
          ],
        },
      ],
    },

    {
      items: [
        { label: "Match Makers", icon: "pi pi-fw pi-user", to: "/MatchMakers" },
      ],
    },
    {
      items: [{ label: "Resoursces", icon: "", to: "/Pages/Resources" }],
    },

    {
      items: [
        {
          label: "Pages",
          icon: "",
          items: [
            { label: "HomePage", to: "/Pages/HomePage" },
            { label: "Home Slider", to: "/Pages/Sliders" },
            { label: "Home Transcends", to: "/Pages/Transcends" },

            { label: "Success Stories", to: "/Pages/Stories" },
            { label: "About Page", to: "/Pages/AboutUs" },
            { label: "Instructors", to: "/Pages/Instructors" },
            { label: "Kundali Matching Page", to: "/Pages/KundliMatchingPage" },
            { label: "FAQs", to: "/Pages/FAQs" },
          ],
        },
      ],
    },
    {
      items: [{ label: "Contact Queries", to: "/ContactQueries" }],
    },
    {
      items: [{ label: "Reported Users", to: "/ReportedUsers" }],
    },
    {
      items: [{ label: "Coupons", to: "/Coupons" }],
    },
    {
      items: [{ label: "Subscription Plans", to: "/SubscriptionPlans" }],
    },
  ];

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
        
          return !item?.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={i} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
    </MenuProvider>
  );
};

export default AppMenu;
