import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Login from './Components/Pages/Login';
import { PrimeReactProvider } from 'primereact/api';

import { RouteList } from './Routers';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./static/css/app/layout.css";
import ToastComp from "./Components/reusable-component/Toast/ToastComp";
import { LayoutProvider } from "./context/layoutcontext";
import Dashboard from './Components/Pages/Dashboard';
import { useSelector } from 'react-redux';


function App() {
  const isAuthenticated = useSelector(state => state.auth.logincheck);

  return (
    <div className="App">
      <PrimeReactProvider>
        <LayoutProvider>
          <ToastComp />
          <BrowserRouter basename={'/'}>
            <Routes>
              <Route exact path="/" element={
                isAuthenticated ? <Dashboard /> : <Login />
              } />
              {RouteList.length > 0 && RouteList.map((item, i) => (
                <Route
                  exact
                  path={item.path}
                  element={
                    <AuthRoute element={item.component} />
                  }
                  key={i}
                />
              ))}
            </Routes>
          </BrowserRouter>
        </LayoutProvider>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
