import { useDispatch, useSelector } from "react-redux";




export const Checkpermission = (page, type = "") => {
//    debugger
    // Access the user data from the Redux state
    let usersss = useSelector((state) => state?.auth?.auth);
  
    if (typeof usersss === "string") {
        if(usersss === ""){
            return false
        }
        usersss = JSON.parse(usersss);
    }
    if (usersss?.userType === "Admin") {
        return true;
    }
    //usersss.auth
    // Check if the user data or permissions are not available
    if (!usersss || !usersss.permissions) {
        return false;
    }

    // Parse the permissions string into an object
    let permissiondata;
    try {
        permissiondata = JSON.parse(usersss.permissions);
    } catch (error) {
        console.error('Error parsing permissions JSON:', error);
        return false;
    }
    if(page==="Users List" || page==="Pages"){
        return true
    }

    // If the user is an Admin, return true
   

    // If `type` is not provided, just check if `page` exists in `permissiondata`
    if (type === "") {
        return permissiondata.hasOwnProperty(page);
    }

    // If `type` is provided, check if both `page` and `type` exist
    if (permissiondata.hasOwnProperty(page)) {
        return permissiondata[page].hasOwnProperty(type);
    }

    // Return false if neither condition is met
    return false;
};
